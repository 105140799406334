import React, { useRef, useEffect, useState } from 'react';
import { Div } from './HeroBgAnimationStyle';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';

const randomBetween = (min, max) => Math.random() * (max - min) + min;

const Particles = () => {
  const particlesRef = useRef();
  const [blackHoles] = useState(() => {
    // Create 5 random black hole positions
    return Array.from({ length: 10 }, () => ({
      x: randomBetween(-5, 5),
      y: randomBetween(-5, 5),
      z: randomBetween(-5, 5),
    }));
  });

  useEffect(() => {
    const particles = particlesRef.current;
    if (!particles) return;

    const particleCount = 100000;
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);

    const textureLoader = new THREE.TextureLoader();
    const particleTexture = textureLoader.load('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTieIECUCz5tx5QtXWcLGQBZODigas4xOkURw&s'); // Load your image here

    for (let i = 0; i < particleCount; i++) {
      const x = (Math.random() - 0.5) * 10;
      const y = (Math.random() - 0.5) * 10;
      const z = (Math.random() - 0.5) * 10;

      positions.set([x, y, z], i * 3);

      const color = new THREE.Color(`hsl(${randomBetween(180, 240)}, 100%, 50%)`);
      colors.set([color.r, color.g, color.b], i * 3);
    }

    particles.geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3)
    );

    particles.geometry.setAttribute(
      'color',
      new THREE.BufferAttribute(colors, 3)
    );

    particles.material.map = particleTexture;
    particles.material.vertexColors = true;
  }, []);

  useFrame(() => {
    if (!particlesRef.current) return;

    const positions = particlesRef.current.geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
      let x = positions[i];
      let y = positions[i + 1];
      let z = positions[i + 2];

      // Apply black hole gravity to particles
      blackHoles.forEach((blackHole) => {
        const dx = blackHole.x - x;
        const dy = blackHole.y - y;
        const dz = blackHole.z - z;
        const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

        // Inverse square law for gravitational pull, with stronger force closer to the black hole
        const force = 0.0125 / (distance * distance);

        x += dx * force;
        y += dy * force;
        z += dz * force;

        // Simulate particle being pulled into the black hole by clamping distance
        if (distance < 0.2) {
          positions[i] = blackHole.x;
          positions[i + 1] = blackHole.y;
          positions[i + 2] = blackHole.z;
        }
      });

      positions[i] = x;
      positions[i + 1] = y;
      positions[i + 2] = z;
    }

    particlesRef.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={particlesRef}>
      <bufferGeometry />
      <pointsMaterial size={0.001}  />
    </points>
  );
};

const HeroBgAnimation = () => (
  <Div>
    <Canvas camera={{ position: [110, 0, 25], fov: 5 }}>
      <Particles />
    </Canvas>
    <svg
      className="BgAnimation__svg"
      viewBox="0 0 602 602"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Your existing SVG content */}
    </svg>
  </Div>
);

export default HeroBgAnimation;
